<template>
    <div class="clueStock-employee">
        <div style="padding-bottom:15px">
            <div class="clearfix table-tools">
                <div class="buttons">
                    <a-form layout='inline'>
                        <a-form-item>
                            <a-button @click="toExport" icon="export" :loading="exportLoading">导出</a-button>
                        </a-form-item>
                    </a-form>
                </div>
                <div class="search">
                    <a-form layout='inline'>
                    <a-form-item>
                      <a-tooltip placement="topLeft" >
                        <template slot="title">
                          <span>员工名称</span>
                        </template>
                        <a-input v-model="paramsData.fuzzy" placeholder="员工名称" style="width: 160px" allowClear/>
                      </a-tooltip>
                    </a-form-item>
                    <a-form-item>
                        <a-tooltip placement="topLeft" >
                        <template slot="title">
                            <span>来源渠道</span>
                        </template>
                        <a-tree-select
                            v-model="paramsData.channel_id"
                            :tree-data="parentChannels"
                            tree-checkable
                            allowClear
                            treeNodeFilterProp="title"
                            :maxTagCount='1'
                            style="width: 240px;"
                            :dropdownStyle="{zIndex:4200,maxHeight:'400px'}"
                            :show-checked-strategy="SHOW_ALL"
                            placeholder="请选择来源渠道"
                        />
                        </a-tooltip>
                    </a-form-item>
                    <a-form-item>
                        <a-tooltip placement="topLeft" >
                        <template slot="title">
                            <span>所属校区</span>
                        </template>
                        <a-select allowClear showSearch placeholder="请选择校区" mode="multiple" :maxTagCount="1" :maxTagTextLength='5' :filterOption="filterOption" @change="handleChange" style="width: 220px;">
                            <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
                        </a-select>
                        </a-tooltip>
                    </a-form-item>
                    <a-form-item>
                        <a-button @click="toScreen" :loading="loading" type="primary">筛选</a-button>
                    </a-form-item>
                    </a-form>
                </div>
            </div>
            <div class="table">
                <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                <a-table size="small" :pagination="false" :bordered='false' rowKey="employee_id" @change="handleSort"
                :columns="columns" :dataSource="list" :scroll="{ x: 1300,y: wHeight }" :customRow="clickRow" :rowClassName="rowClassName">
                    <template slot="employee_name" slot-scope="text">
                        <a href="javascript:;">{{text}}</a>
                    </template>
                    <template slot="attend" slot-scope="text,record">
                        <span>{{text}}%</span>
                    </template>
                    <template slot="change" slot-scope="text,record">
                        <span>{{text}}%</span>
                    </template>
                </a-table>
            </div>
        </div>
        <detailModal :itemID='itemID' :isTab='1' :name='name' :search='paramsData' v-if="isDetailModal" />
    </div>
</template>

<script>

import ranges from "@/common/mixins/ranges"
import moment from 'moment'
import detailModal from './detailModal'
export default {
  name: 'culeStock',
  provide() {
    return {
        parent: this
    }
  },
  components: {
      detailModal,
  },
  data() {
    return { 
      loading:false,
      studios:[],
      counts:[],
      loadingS:false,
      exportLoading:false,
      sankey_data:[],
      parentChannels:[],
      link:[],
      paramsData:{
        studio_id:'',
      },
      channelsId:[],
      list:[],
      isDetailModal:false,
      itemID:'',
      clickId:'',
      myHeight:0,
      name:'',
      filteredInfo: null,
      sortedInfo: null,
    }
  },
  mixins: [ranges],
  computed:{
      wHeight(){
          return Number(this.myHeight) - 350
      },
      columns() {
        let { sortedInfo, filteredInfo } = this
        sortedInfo = sortedInfo || {};
        filteredInfo = filteredInfo || {};
        const columns = [
          { title: '#', width:'55px', customRender:(text,record,index)=>`${index+1}`, key: 'index', fixed: 'left'},
          { title: '员工名称', dataIndex: 'employee_name', key: 'employee_name',ellipsis:true,
            filteredValue: filteredInfo.employee_name || null,
            onFilter: (value, record) => record.employee_name.includes(value),
            sorter: (a, b) => a.employee_name.length - b.employee_name.length,
            sortOrder: sortedInfo.columnKey === 'employee_name' && sortedInfo.order,
            scopedSlots: { customRender: 'employee_name' }
          },
          { title: '总量', dataIndex: 'total', key: 'total',align:"center",
            sorter: (a, b) => Number(a.total) - Number(b.total),
            sortOrder: sortedInfo.columnKey === 'total' && sortedInfo.order
          },
          { title: '未跟进', dataIndex: '未跟进', key: '未跟进',scopedSlots: { customRender: 'follow_status0' },align:"center",
            sorter: (a, b) => Number(a['未跟进']) - Number(b['未跟进']),
            sortOrder: sortedInfo.columnKey === '未跟进' && sortedInfo.order
          },
          { title: '跟进中', dataIndex: '跟进中', key: '跟进中',scopedSlots: { customRender: 'follow_status1' },align:"center",
            sorter: (a, b) => Number(a['跟进中']) - Number(b['跟进中']),
            sortOrder: sortedInfo.columnKey === '跟进中' && sortedInfo.order
          },
          { title: '已预约', dataIndex: '已预约', key: '已预约',scopedSlots: { customRender: 'follow_status2' },align:"center",
            sorter: (a, b) => Number(a['已预约']) - Number(b['已预约']),
            sortOrder: sortedInfo.columnKey === '已预约' && sortedInfo.order
          },
          { title: '长期跟进', dataIndex: '长期跟进', key: '长期跟进',scopedSlots: { customRender: 'follow_status8' },align:"center",
            sorter: (a, b) => Number(a['长期跟进']) - Number(b['长期跟进']),
            sortOrder: sortedInfo.columnKey === '长期跟进' && sortedInfo.order
          },
          { title: '暂搁', dataIndex: '暂搁', key: '暂搁',scopedSlots: { customRender: 'follow_status6' },align:"center",
            sorter: (a, b) => Number(a['暂搁']) - Number(b['暂搁']),
            sortOrder: sortedInfo.columnKey === '暂搁' && sortedInfo.order
          },
          { title: '未接通', dataIndex: '未接通', key: '未接通',scopedSlots: { customRender: 'follow_status5' },align:"center",
            sorter: (a, b) => Number(a['未接通']) - Number(b['未接通']),
            sortOrder: sortedInfo.columnKey === '未接通' && sortedInfo.order
          },
          { title: '无效', dataIndex: '无效', key: '无效',scopedSlots: { customRender: 'follow_status7' },align:"center",
            sorter: (a, b) => Number(a['无效']) - Number(b['无效']),
            sortOrder: sortedInfo.columnKey === '无效' && sortedInfo.order
          },
          { title: '已成单', dataIndex: '已成单', key: '已成单',scopedSlots: { customRender: 'follow_status4' },align:"center",
            sorter: (a, b) => Number(a['已成单']) - Number(b['已成单']),
            sortOrder: sortedInfo.columnKey === '已成单' && sortedInfo.order
          },
          { title: '已退款', dataIndex: '已退款', key: '已退款',scopedSlots: { customRender: 'follow_status4' },align:"center",
            sorter: (a, b) => Number(a['已退款']) - Number(b['已退款']),
            sortOrder: sortedInfo.columnKey === '已成单' && sortedInfo.order
          },
          { title: '已到访', dataIndex: '已到访', key: '已到访',scopedSlots: { customRender: 'follow_status3' },align:"center",
            sorter: (a, b) => Number(a['已到访']) - Number(b['已到访']),
            sortOrder: sortedInfo.columnKey === '已到访' && sortedInfo.order
          },
          { title: '到访率', dataIndex: '到访率', key: '到访率',align:"center",
            scopedSlots: { customRender: 'attend' },
            sorter: (a, b) => Number(a['到访率']) - Number(b['到访率']),
            sortOrder: sortedInfo.columnKey === '到访率' && sortedInfo.order
          },
          { title: '签单转化率', dataIndex: '签单转化率', key: '签单转化率',align:"center",
            scopedSlots: { customRender: 'change' },
            sorter: (a, b) => Number(a['签单转化率']) - Number(b['签单转化率']),
            sortOrder: sortedInfo.columnKey === '签单转化率' && sortedInfo.order
          },
        ]
        return columns
      }
  },
  created() {
    this.myHeight = this.windowHeight()
    this.toScreen()
    this.getStudio()
    this.getSourceChannel()
  },
  methods: {
    moment,
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    async getList(obj) {
        this.loading = true
        let res = await this.$store.dispatch('analysisCrmLeadsEmployeeAction', obj)
        let list = res.data
        this.list = list
        this.loading = false
    },
    async getStudio() {
      let res = await this.$store.dispatch('searchBelongStudioAction', {})
      this.studios = res.data
    },
    async getCount(obj){
      let res = await this.$store.dispatch('analysisCrmChannelCountAction', obj)
      this.counts = res.data
    },
    async getSourceChannel() {
      let res = await this.$store.dispatch('searchSourceChannelCascaderAction', {})
      this.parentChannels = res.data
    },
    handleChange(value){
      this.paramsData.studio_id = value
    },
    handleButChange(e){
      this.toScreen()
    },
    toScreen(){
      let obj = {
        search:this.paramsData
      }
      this.getList(obj)
    },
    clickRow(record, index){
        return {
            on: {
                click: () => {
                    this.clickId = record.employee_id
                    this.itemID = record.employee_id
                    this.name = record.employee_name
                    this.isDetailModal = true
                }
            }
        }
    },
    rowClassName(record, index) {
        let className = ""
        if (record.employee_id == this.clickId) {
            className = "rowActive"
            this.$emit('change', this.clickId)
        }
        return className;
    },
    hideDetailModal(){
        this.isDetailModal = false
    },
    windowHeight() { 
        var de = document.documentElement
        return self.innerHeight||(de && de.offsetHeight)||document.body.offsetHeight;
    },
    handleSort(pagination, filters, sorter){
      this.filteredInfo = filters
      this.sortedInfo = sorter
    },
    async toExport(){
        this.exportLoading = true
        let exportData = {
            search:this.paramsData.search,
            sort:this.paramsData.sort
        }

        let res = await this.$store.dispatch('analysisCrmLeadsEmployeeExportAction', exportData)
        const blob = new Blob([res], {type: 'application/vnd.ms-excel'})
        if ('download' in document.createElement('a')) { 
            const link = document.createElement('a')
            link.download = `员工线索存量分析.${moment().format('YYYY.MM.DD')}.xlsx`
            link.style.display = 'none'
            link.href = URL.createObjectURL(blob)
            document.body.appendChild(link)
            link.click()
            URL.revokeObjectURL(link.href) 
            document.body.removeChild(link)
        } else { //其他浏览器
            navigator.msSaveBlob(blob, fileName)
        }

        this.exportLoading = false
    },
  }
}
</script>

<style lang="scss">
.rowActive{
  background:#e6fff6
}
</style>