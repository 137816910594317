<template>
    <div>
        <div class="">
            <div class="clearfix table-tools">
                <div class="buttons">
                </div>
                <div class="search">
                    <a-form layout='inline'>
                    <a-form-item>
                      <a-input-number v-model="paramsData.days" placeholder="请输入" style="width: 160px"/>
                    </a-form-item>
                    <a-form-item>
                        <a-button @click="toScreen" :loading="loading" type="primary">筛选</a-button>
                    </a-form-item>
                    </a-form>
                </div>
            </div>   
            <div class="dashboard bk-group" style="padding-top: 15px">
                <a-row class="mb16" :gutter="16">

                    <a-col :lg="24" :xl="24">
                        <LRanking title="校区线索存量统计" :rank_data="stockData" :loading="loading" subtitle=" " :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                    </a-col>
                    
                </a-row>
            </div>
        </div>
    </div>
</template>

<script>

import ranges from "@/common/mixins/ranges"
import moment from 'moment'
export default {
  name: 'stock',
  provide() {
    return {
        parent: this
    }
  },
  data() {
    return {
      loading:false,
      paramsData:{
          days:30
      },
      stockData:{}
    }
  },
  mixins: [ranges],
  created() {
    this.toScreen()
  },

  methods: {
    moment,
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    async getList(obj) {
        this.loading = true
        let res = await this.$store.dispatch('analysisCrmLeadsStockAction', obj)
        this.stockData = res.data.studio_sale_statis
        this.loading = false
    },
    toScreen(){
      let obj = {
        search:this.paramsData
      }
      this.getList(obj)
    },
  }
}
</script>

<style lang="scss">

</style>