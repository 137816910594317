<template>
        <a-modal :title='name' v-model="visible" :footer='null' :closable="false"
            cancelText="取消" centered okText="转移" width="80%"
            @ok="handleOk" @cancel="handleCancel">
            <div class="main-box">
                <div class="table">
                    <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                    <a-table size="small" :pagination="false" :bordered='false' rowKey="channel_id"
                    :columns="columns" :dataSource="list" :scroll="{ x: 700, y: 500 }" @change="handleSort">
                        <template slot="attend" slot-scope="text,record">
                            <span>{{text}}%</span>
                        </template>
                        <template slot="change" slot-scope="text,record">
                            <span>{{text}}%</span>
                        </template>
                    </a-table>
                </div>
            </div>
        </a-modal>
</template>

<script>
    export default {
        name:'detailModal',
        inject: ['parent'],
        data() {
            return {
                list:[],
                loading: false,
                ModalTitle:'',
                visible:false,
                filteredInfo: null,
                sortedInfo: null,
            }
        },
        props: {
            itemID:Number,
            isTab:Number,
            search:Object,
            name:String,
        },
        computed: {
            columns() {
                let { sortedInfo, filteredInfo } = this
                sortedInfo = sortedInfo || {};
                filteredInfo = filteredInfo || {};
                const columns = [
                { title: '#', width:'55px', customRender:(text,record,index)=>`${index+1}`, key: 'index', fixed: 'left'},
                { title: '渠道名称',width:'160px', dataIndex: 'channel_name', key: 'channel_name',ellipsis:true,
                    filteredValue: filteredInfo.channel_name || null,
                    onFilter: (value, record) => record.channel_name.includes(value),
                    sorter: (a, b) => a.channel_name.length - b.channel_name.length,
                    sortOrder: sortedInfo.columnKey === 'channel_name' && sortedInfo.order,
                },
                { title: '总量', dataIndex: 'total', key: 'total',align:"center",
                    sorter: (a, b) => Number(a.total) - Number(b.total),
                    sortOrder: sortedInfo.columnKey === 'total' && sortedInfo.order
                },
                { title: '未跟进', dataIndex: '未跟进', key: '未跟进',scopedSlots: { customRender: 'follow_status0' },align:"center",
                    sorter: (a, b) => Number(a['未跟进']) - Number(b['未跟进']),
                    sortOrder: sortedInfo.columnKey === '未跟进' && sortedInfo.order
                },
                { title: '跟进中', dataIndex: '跟进中', key: '跟进中',scopedSlots: { customRender: 'follow_status1' },align:"center",
                    sorter: (a, b) => Number(a['跟进中']) - Number(b['跟进中']),
                    sortOrder: sortedInfo.columnKey === '跟进中' && sortedInfo.order
                },
                { title: '已预约', dataIndex: '已预约', key: '已预约',scopedSlots: { customRender: 'follow_status2' },align:"center",
                    sorter: (a, b) => Number(a['已预约']) - Number(b['已预约']),
                    sortOrder: sortedInfo.columnKey === '已预约' && sortedInfo.order
                },
                { title: '长期跟进', dataIndex: '长期跟进', key: '长期跟进',scopedSlots: { customRender: 'follow_status8' },align:"center",
                    sorter: (a, b) => Number(a['长期跟进']) - Number(b['长期跟进']),
                    sortOrder: sortedInfo.columnKey === '长期跟进' && sortedInfo.order
                },
                { title: '暂搁', dataIndex: '暂搁', key: '暂搁',scopedSlots: { customRender: 'follow_status6' },align:"center",
                    sorter: (a, b) => Number(a['暂搁']) - Number(b['暂搁']),
                    sortOrder: sortedInfo.columnKey === '暂搁' && sortedInfo.order
                },
                { title: '未接通', dataIndex: '未接通', key: '未接通',scopedSlots: { customRender: 'follow_status5' },align:"center",
                    sorter: (a, b) => Number(a['未接通']) - Number(b['未接通']),
                    sortOrder: sortedInfo.columnKey === '未接通' && sortedInfo.order
                },
                { title: '无效', dataIndex: '无效', key: '无效',scopedSlots: { customRender: 'follow_status7' },align:"center",
                    sorter: (a, b) => Number(a['无效']) - Number(b['无效']),
                    sortOrder: sortedInfo.columnKey === '无效' && sortedInfo.order
                },
                { title: '已成单', dataIndex: '已成单', key: '已成单',scopedSlots: { customRender: 'follow_status4' },align:"center",
                    sorter: (a, b) => Number(a['已成单']) - Number(b['已成单']),
                    sortOrder: sortedInfo.columnKey === '已成单' && sortedInfo.order
                },
                { title: '已到访', dataIndex: '已到访', key: '已到访',scopedSlots: { customRender: 'follow_status3' },align:"center",
                    sorter: (a, b) => Number(a['已到访']) - Number(b['已到访']),
                    sortOrder: sortedInfo.columnKey === '已到访' && sortedInfo.order
                },
                { title: '到访率', dataIndex: '到访率', key: '到访率',align:"center",
                    scopedSlots: { customRender: 'attend' },
                    sorter: (a, b) => Number(a['到访率']) - Number(b['到访率']),
                    sortOrder: sortedInfo.columnKey === '到访率' && sortedInfo.order
                },
                { title: '签单转化率', dataIndex: '签单转化率', key: '签单转化率',align:"center",
                    scopedSlots: { customRender: 'change' },
                    sorter: (a, b) => Number(a['签单转化率']) - Number(b['签单转化率']),
                    sortOrder: sortedInfo.columnKey === '签单转化率' && sortedInfo.order
                },
                ]
                return columns
            }
        },
        created () {
            this.visible = true
            this.getList()
        },
        methods: {
            async getList(){
                this.loading=true
                if(this.isTab === 1){
                    let res = await this.$store.dispatch('analysisCrmLeadsEmployeeLeadsDetailAction', {employee_id:this.itemID,search:this.search})
                    this.list = res.data
                }else{
                    let res = await this.$store.dispatch('analysisCrmLeadsStudioLeadsDetailAction', {search:this.search})
                    this.list = res.data
                }
                this.loading=false;
            },
            handleOk() {},
            handleCancel(){
                this.parent.hideDetailModal()
            },
            handleSort(pagination, filters, sorter){
                this.filteredInfo = filters
                this.sortedInfo = sorter
            }
        },
    }
</script>

<style lang="scss">

</style>